import React from 'react';
import { Link, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Layout from '../components/en';
import SEO from '../components/seo';

import history from '../images/family/historique.jpg';
import sylvain from '../images/family/sylvain_furlan.jpg';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/team/**/*.en.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            facebook
            twitter
            instagram
            cover {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            avatar {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const Team = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title="Furlan - Portrait and history of the brand, of sylvain Furlan and Furlan riders."
        description="Discover our team"
        pathname="/fr/team/"
        keywords={['snowboard', 'boards', 'polyvalence']}
      />
      <div className="teamPage">
        <img src={history} alt="Furlan Snowboards" />
        <blockquote className="blockquote-credit">
          @actsnowboarding (David Tchag)
        </blockquote>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-4 mb-5 mt-lg-5">
              <p className="lead ml-md-4">
                Furlan has a unique personality, that embody's Sylvain's ideals,
                its founder. The goal of this passionate and audacious shaper is to
                create board that bring as much pleasure on the slope as in powder,
                while preserving a freestyle feeling. For this, 10 years ago
                Sylvain created the atypical shape which grafts a long nose onto a
                freestyle board. Divergent to the trend at the time, he was in
                reality a pioneer! 10 years later, he has never been as up to date
                and Furlan is making his place on the market thanks to a one of a
                kind 'touch'. Furthermore, Sylvain doesn't stop there and keeps
                innovating with boards like the excellent Astero, with its
                futuristic look.
              </p>
              <p className="ml-md-4">- Act Snowboarding Magazine</p>
            </div>
            <div className="col-md-7 offset-md-1 ml-md-4">
              <img src={sylvain} alt="Furlan Snowboards" className="sylvain" />
              <p className="mt-3 ml-md-4">
                On the left, my first board, my Christmas present when I was 11, in 1984. This gift changed my life ! My 12 years old neighbor wanted the same one so his father helped us to make one. This was my first experience, building a board. We used to ride them in pow with moonboots and footstraps. On its right, the "Blood and Fire", the first board I made.
              </p>
              <p className="mt-3 ml-md-4">
                It's the concept I keep to this day: re-draw big tips on a freestyle board so as to get that floatability while keeping a short board behavior In the middle. My concept was so good to ride on the snow that I decided to become a handmade brand and commercialize my boards. I didn't want any wood designed board like other handcraft brands so I decided to put a flashy top sheet. On the right my first logo,  inspired by an old logo from the chocolate brand Poulain. During this period, Furlan Snowboards was a non official and very confidential snowboard brand.
              </p>
              <p className="mt-3 ml-md-4">
                So I officially created Furlan Snowboards in 2008 after I've been shaping those orange snowboards since 1998 as a craftsman. Today, my boards are still the fruit of my dreams: back to the roots, adapting powder boards to young generations in search of fun. I create the most versatile and fun snowboards as possible. Whatever their initial program, freestyle, freeride, backcountry, carving... my boards always have a little something more to fly in powder.
              </p>
              <p className="mt-3 ml-md-4">
                Some times I can surprise, we are often told "it's an American brand isn't it?". Haha, it's funny hearing that... In reality it's French, we are based in Isère, at the base of les 7 laux-prapoutel ski resort, stuck between Chambéry and Grenoble. But we do find similarities with certain U.S aspects: we live in mountains, in the middle of cows, in a western like landscape. We are so lucky living here !
              </p>
              <p className="mr-4 ml-md-4">
                - Sylvain Furlan
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            {posts.map(({ node: post }) => (
              <div className="col-6 col-md-4 px-1 px-md-4 py-md-3" key={post.id}>
                <Link to={post.frontmatter.path}>
                  <Img
                    fluid={post.frontmatter.avatar.childImageSharp.fluid}
                    alt="Furlan Snowboards"
                  />
                  <h4 className="mt-3">{post.frontmatter.title}</h4>
                </Link>
                {!post.frontmatter.facebook === '' && (
                  <a
                    href={post.frontmatter.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook mx-2" />
                  </a>
                )}
                {!post.frontmatter.twitter === '' && (
                  <a
                    href={post.frontmatter.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab  fa-twitter mx-2" />
                  </a>
                )}
                {!post.frontmatter.instagram === '' && (
                  <a
                    href={post.frontmatter.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab  fa-instagram  mx-2" />
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Team;
